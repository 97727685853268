import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { Location } from '../../api/sentinel';
import { ProjectListFilter } from '../services/projectService';
import { setPref, getPref }  from "../../services/localStoragePreferences";

const FilterBar = ({
                       filter,
                       setFilter,
                       locations,
                   }: {
    filter: ProjectListFilter;
    setFilter: (a: ProjectListFilter) => void;
    locations: Location[];
}) => {
    const SENTINEL_PRJ_LST__LOCATION = "SNTL_PRJ_LST__location";
    const SENTINEL_PRJ_LST__STATUS = "SNTL_PRJ_LST__status";

    const { t } = useTranslation();
    const translation = {
        filterAllLocation: t('filterBar_filterAllLocation'),
        filterLocation: t('filterBar_filterLocation'),
        filterStatus: t('filterBar_status'),
        menuItemActive: t('filterBar_menuItem_active'),
        menuItemAll: t('filterBar_menuItem_all'),
        menuItemInactive: t('filterBar_menuItem_inactive'),
    };

    const handleLocationChange = (event: SelectChangeEvent) => {
        const locationName = locations?.find(loc => loc.locationId === parseInt(event.target.value))?.location || '';
        setPref(SENTINEL_PRJ_LST__LOCATION, locationName);
        setFilter({ status: filter.status, locationId: event.target.value });
    };

    const handleStatusChange = (event: SelectChangeEvent) => {
        setPref(SENTINEL_PRJ_LST__STATUS, event.target.value);
        setFilter({status: event.target.value as 'active' | 'inactive' | 'all', locationId: filter.locationId});
    };

    useEffect(() => {
        const pref = {
            locationVal: getPref(SENTINEL_PRJ_LST__LOCATION),
            statusVal: getPref(SENTINEL_PRJ_LST__STATUS),
        };
        const getLocationId = locations?.find(loc => loc.location === pref.locationVal)?.locationId?.toString();
        setFilter({
            status: pref.statusVal as 'active' | 'inactive' | 'all' || filter.status,
            locationId: getLocationId || filter.locationId
        });
    },[filter.locationId, filter.status, setFilter, locations])

    return (
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={8}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="locationSelect">
                        {translation.filterLocation}
                    </InputLabel>
                    <Select
                        labelId="locationSelect"
                        value={filter.locationId}
                        onChange={handleLocationChange}
                        data-testid="locationSelect"
                    >
                        <MenuItem
                            key=""
                            value=""
                            data-testid="locationEmptyElement"
                        >
                            {translation.filterAllLocation} *
                        </MenuItem>
                        {locations?.map(location => {
                            return (
                                <MenuItem
                                    key={location.locationId ?? -1}
                                    value={String(location.locationId)}
                                    data-testid={`locationElement-${location.locationId}`}
                                >
                                    {location.location}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="filter-status-label">
                        {translation.filterStatus}
                    </InputLabel>
                    <Select
                        labelId="filter-status-label"
                        id="filter-status-select"
                        value={filter.status}
                        onChange={handleStatusChange}
                        data-testid="filter-status-select"
                    >
                        <MenuItem value={'active'} data-testid={'active'}>
                            {translation.menuItemActive}
                        </MenuItem>
                        <MenuItem value={'inactive'} data-testid={'inactive'}>
                            {translation.menuItemInactive}
                        </MenuItem>
                        <MenuItem value={'all'} data-testid={'all'}>
                            {translation.menuItemAll}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FilterBar;

import addDays from 'date-fns/addDays';
import { User , ProjectLink } from '../../api/sentinel';

export type EngagementModel =
    | ''
    | 'CAPACITY'
    | 'OUTCOME'
    | 'COMANAGED'
    | 'ASSESSMENT';

export type ProjectFormModel = {
    activeProjectSwitch: boolean;
    businessId: string;
    clientName: string;
    engagementManager: User;
    endDate: Date;
    locations: string[];
    projectId?: string;
    projectName: string;
    startDate: Date;
    engagementModel: EngagementModel;
    scrumMaster: User;
    practicePartner: User;
    projectLinks: Array<ProjectLink>;
    updatedBy?: string;
    updatedOn?: Date;
};

const projectFormModel: ProjectFormModel = {
    activeProjectSwitch: true,
    businessId: '',
    clientName: '',
    engagementManager: {},
    endDate: addDays(new Date(), 1),
    locations: [],
    projectId: undefined,
    projectName: '',
    startDate: new Date(),
    engagementModel: '',
    scrumMaster: {},
    practicePartner: {},
    projectLinks: [],
};

export default projectFormModel;

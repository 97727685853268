import {Sprint} from "../../api/sentinel";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import WidgetContainer from "../../dashboard/common/widget/WidgetContainer";
import WidgetHeader from "../../dashboard/common/widget/WidgetHeader";
import dateService from "../../services/dateService";


const SprintRevisions = ({revisions} : {revisions? : Sprint[]}) => {

    const {t} = useTranslation();

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: keyof Sprint ,
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field:  fieldName,
            headerName: t( `sprintRevision_${fieldName}` ),
            ...commonAttributes,
            ...options,
        };
    };

    const sprintRevisionColumns: GridColDef[] = [
        field('updatedOn', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateTimeFormat(value),
        }),
        field('updatedBy', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
        }),
        field('name', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true
        }),
        field('sprintStartDate', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateFormat(value),
        }),
        field('sprintEndDate', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateFormat(value),
        }),
        field('sprintGoal', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true
        }),
        field('sprintStatus', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true
        }),
    ];

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr'}
            gridTemplateAreas={'"H"\n"A"\n"B"'}
        >
            <WidgetHeader
                gridArea={'H'}
                title={t('sprint_revisions_title')}
                subtitle={t('sprint_revisions_subtitle')}
                trend={undefined}
            />
            <DataGrid
                density={'standard'}
                getRowId={(sprint: Sprint) => sprint.updatedOn?.getTime() ?? ''}
                columns={sprintRevisionColumns}
                rows={revisions ? revisions : []}
                disableRowSelectionOnClick
                sx={{
                    borderTopWidth: 0
                }}
            />
        </WidgetContainer>
        );
};

export default SprintRevisions;
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import RevisionsIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme/theme';
import {t} from "i18next";

export default function NavBar({
    projectName,
    projectId,
}: {
    grade?: string;
    projectName?: string;
    projectId: string;
}) {
    const projectRevisionsLink = `/Project/${projectId}/revisions`;
    const navigate = useNavigate();
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                padding: '0 3%',
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <IconButton
                        href={'/Projects'}
                        title={'back to projects page'}
                        data-testid="backToProjectsButton"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1" component="h1" mb="0" ml="10px">
                        {projectName}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                >
                    <ReadOnlyTooltip
                        childDisabled={!tokenService.hasEditingRights()}
                    >
                        <Button
                            startIcon={<CreateIcon />}
                            size="small"
                            color="primary"
                            href={`/Project/${projectId}`}
                            disabled={!tokenService.hasEditingRights()}
                            data-testid="editProjectButton"
                        >
                            Edit project
                        </Button>
                        <Button
                            startIcon={<RevisionsIcon />}
                            aria-label={t('projectDashboard_seeRevisions_ariaLabel')}
                            onClick={() => navigate(projectRevisionsLink)}
                            size="small"
                            color="primary"
                        >
                            {t('projectDashboard_seeRevisions_link')}
                        </Button>
                     </ReadOnlyTooltip>
                </Stack>
            </Stack>
        </Container>
    );
}

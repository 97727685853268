import {
    FormProvider,
    Resolver,
    useForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form';
import Grid from '@mui/material/Grid';
import TextFieldInput from '../../common/formContextBoundControls/TextFieldInput';
import DateInput from '../../common/formContextBoundControls/DateInput';
import SwitchInput from '../../common/formContextBoundControls/SwitchInput';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ProjectFormModel } from '../services/projectFormModel';
import { yupResolver } from '@hookform/resolvers/yup';
import getValidationSchema from '../../project/services/validationSchema';
import { useTranslation } from 'react-i18next';
import ProjectLocations from './ProjectLocations';
import { useEffect } from 'react';
import SelectInput from '../../common/formContextBoundControls/SelectInput';
import ProjectLinksPanel from './ProjectLinksPanel';
import tokenService from '../../authentication/tokenService';
import useProvidedMetricSpecs from '../../services/useProvidedMetricSpecs';
import CustomPeoplePicker from './CustomPeoplePicker';

const ProjectForm = ({
    formModel,
    onSave,
    formDirtyStatusCallback,
}: {
    formModel: ProjectFormModel;
    onSave: (form: ProjectFormModel) => Promise<void>;
    formDirtyStatusCallback: (b: boolean) => void;
}) => {
    const { t } = useTranslation();
    const translation = {
        clientName: t('projectForm_clientName_label'),
        engagementManager: t('projectForm_engagementManager_label'),
        endDate: t('global_endDate'),
        endDateTooltip: t('projectForm_endDate_tooltip'),
        location: t('projectForm_location_label'),
        businessId: t('projectForm_businessId_label'),
        projectName: t('projectForm_projectName_label'),
        startDate: t('global_startDate'),
        startDateTooltip: t('projectForm_startDate_tooltip'),
        isActiveProject: t('projectForm_isActiveProject_label'),
        isActiveProjectTooltip: t('projectForm_isActiveProject_tooltip'),
        engagementModel: t('projectForm_engagementModel_label'),
        engagementModelTooltip: t('projectForm_engagementModel_tooltip'),
        engagementModelOutcome: t('projectForm_engagementModel__OUTCOME'),
        engagementModelComanaged: t('projectForm_engagementModel__COMANAGED'),
        engagementModelCapacity: t('projectForm_engagementModel__CAPACITY'),
        engagementModelAssessment: t('projectForm_engagementModel__ASSESSMENT'),
        submitBtn: t('projectForm_submit_btn'),
        scrumMaster: t('projectForm_scrumMaster_tooltip'),
        practicePartner: t('projectForm_practicePartner_tooltip'),
    };

    const engagementModel = [
        { value: 'OUTCOME', label: translation.engagementModelOutcome },
        { value: 'COMANAGED', label: translation.engagementModelComanaged },
        { value: 'CAPACITY', label: translation.engagementModelCapacity },
        { value: 'ASSESSMENT', label: translation.engagementModelAssessment },
    ];

    const form: UseFormReturn<ProjectFormModel, UseFormProps> =
        useForm<ProjectFormModel>({
            defaultValues: formModel,
            resolver: yupResolver(
                getValidationSchema(),
            ) as Resolver<ProjectFormModel>,
            mode: 'onBlur',
            reValidateMode: 'onChange',
        });

    useEffect(() => {
        formDirtyStatusCallback(form.formState.isDirty);
    }, [form.formState.isDirty, formDirtyStatusCallback]);

    const { projectProvidedMetricSpecs } = useProvidedMetricSpecs();

    const isEditable = (fieldName: string): boolean => {
        const requiredAccessRole =
            projectProvidedMetricSpecs[fieldName]?.restriction?.writeAccessRole;

        return (
            !requiredAccessRole ||
            (requiredAccessRole === 'leader' && tokenService.hasLeadRights())
        );
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSave)}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldInput
                            name="clientName"
                            label={translation.clientName}
                            required
                            data-testid="clientName"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldInput
                            name="projectName"
                            label={translation.projectName}
                            required
                            data-testid="projectName"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextFieldInput
                            name="businessId"
                            label={translation.businessId}
                            data-testid="businessId"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomPeoplePicker
                            name={'engagementManager'}
                            tooltip_title={translation.engagementManager}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CustomPeoplePicker
                            name={'scrumMaster'}
                            tooltip_title={translation.scrumMaster}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomPeoplePicker
                            name={'practicePartner'}
                            tooltip_title={translation.practicePartner}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <DateInput
                            name="startDate"
                            label={translation.startDate}
                            tooltip_title={translation.startDateTooltip}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            name="endDate"
                            label={translation.endDate}
                            required
                            tooltip_title={translation.endDateTooltip}
                            data-testid="endDate"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SwitchInput
                            name="activeProjectSwitch"
                            tooltip_title={translation.isActiveProjectTooltip}
                            label={translation.isActiveProject}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ProjectLocations
                            name="locations"
                            label={translation.location}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {/* Space */}
                    </Grid>
                    <Grid item xs={7}>
                        <ProjectLinksPanel name="projectLinks" />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            disabled={!isEditable('engagementModel')}
                            label={translation.engagementModel}
                            name="engagementModel"
                            options={engagementModel}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={12} sm={12}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                type="submit"
                                data-testid="submitButton"
                            >
                                {translation.submitBtn}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default ProjectForm;

import BugReportIcon from '@mui/icons-material/BugReport';
import Button from '@mui/material/Button';
import CodeIcon from '@mui/icons-material/Code';
import Grid from '@mui/material/Grid';
import LoopIcon from '@mui/icons-material/Loop';
import NatureIcon from '@mui/icons-material/Nature';
import Typography from '@mui/material/Typography';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormProvider,
    Resolver,
    useForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form';
import DateInput from '../../common/formContextBoundControls/DateInput';
import TextFieldInput from '../../common/formContextBoundControls/TextFieldInput';
import SwitchInput from '../../common/formContextBoundControls/SwitchInput';
import SelectInput from '../../common/formContextBoundControls/SelectInput';
import { useTranslation } from 'react-i18next';
import { generateValidationSchema } from '../services/validationSchema';
import Divider from '@mui/material/Divider';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import { Fragment, useEffect } from 'react';
import MetricCategoryForm, {
    MetricData,
    MetricsCategoryData,
    SelectMetricData,
} from './MetricCategoryForm';
import { SprintFormModel } from '../services/sprintMapper';
import {
    SelectMetricSpec,
    MetricSpec,
} from '../../services/useProvidedMetricSpecs';
import addDays from 'date-fns/addDays';

const SprintForm = ({
    model,
    save,
    formDirtyStatusCallback,
    providedMetricSpecs,
    isCreating,
    isPreviousSprintCompleted,
}: {
    model: Partial<SprintFormModel>;
    save: (m: SprintFormModel) => Promise<void>;
    formDirtyStatusCallback: (value: boolean) => void;
    providedMetricSpecs: Record<string, MetricSpec>;
    isCreating: boolean;
    isPreviousSprintCompleted: (endDate: Date) => boolean;
}) => {
    const validationSchema = generateValidationSchema(providedMetricSpecs);
    const { t } = useTranslation();
    const translation = {
        endDate: t('global_endDate'),
        endDateToolTip: t('sprintForm_endDate_tooltip'),
        metricsInfoIntro: t('sprintForm_metricsInfo_intro'),
        metricsInfoTitle: t('sprintForm_metricsInfo_title'),
        saveSprint: t('sprintForm_saveSprint_label'),
        sprintName: t('sprintForm_sprint_name'),
        sprintGoal: t('sprintForm_sprintGoal_label'),
        sprintGoalHelper: t('sprintForm_sprintGoal_HelperText'),
        sprintGoalSucceeded: t('sprintForm_sprintGoalSucceeded_label'),
        sprintGoalSucceededTooltip: t('sprintForm_sprintGoalSucceeded_tooltip'),
        sprintGoalTooltip: t('sprintForm_sprintGoal_tooltip'),
        startDate: t('global_startDate'),
        sprintNameToolTip: t('sprintForm_sprintName_tooltip'),
        startDateToolTip: t('sprintForm_startDate_tooltip'),
        sprintStatus: t('sprintForm_sprintStatus_label'),
        sprintStatusTooltip: t('sprintForm_sprintStatus_tooltip'),
        sprintNamePlaceholder: t('sprintForm_sprintName_placeholder'),
    };

    const metricsDataTemplate: Record<string, MetricsCategoryData> = {
        AGILITY: { icon: LoopIcon, metrics: {} },
        QUALITY: { icon: BugReportIcon, metrics: {} },
        SOFTWARE_HABITABILITY: { icon: CodeIcon, metrics: {} },
        ENVIRONMENT: { icon: NatureIcon, metrics: {} },
        RELEASE: { icon: NewReleasesIcon, metrics: {} },
    };

    const metricDataFromSpec = (spec: MetricSpec): MetricData => {
        const metricData: MetricData = {} as MetricData;

        switch (spec.type) {
            case 'INTEGER':
            case 'DECIMAL':
                metricData.type = 'text';
                break;
            case 'TEAM_MORALE':
                metricData.type = 'morale';
                break;
            default:
                metricData.type = 'select';
                (metricData as SelectMetricData).options = (
                    spec as SelectMetricSpec
                ).options;
                break;
        }
        metricData.valueConstraint = spec.valueConstraint;

        return metricData;
    };

    const metricsData = Object.entries(providedMetricSpecs).reduce(
        (a, [key, spec]) => {
            a[spec.category].metrics[key] = metricDataFromSpec(spec);
            return a;
        },
        metricsDataTemplate,
    );

    const iterationGoalSucceededOptions = (
        metricsData.AGILITY.metrics.iterationGoalSucceeded as SelectMetricData
    ).options.map(value => ({
        value,
        label: t(`sprintForm_iterationGoalSucceeded__${value}`),
    }));

    if (
        !metricsData.AGILITY.metrics.iterationGoalSucceeded.valueConstraint
            ?.required
    ) {
        iterationGoalSucceededOptions.unshift({
            value: '',
            label: t('global_select_option'),
        });
    }

    const form: UseFormReturn<SprintFormModel, UseFormProps> =
        useForm<SprintFormModel>({
            defaultValues: model,
            resolver: yupResolver(
                validationSchema,
            ) as Resolver<SprintFormModel>,
            mode: 'onBlur',
            reValidateMode: 'onChange',
        });

    useEffect(() => {
        formDirtyStatusCallback(form.formState.isDirty);
    }, [form.formState.isDirty, formDirtyStatusCallback]);

    useEffect(() => {
        // naive implementation, will pick the first error, good enough
        const keys = Object.keys(form.formState.errors);
        if (keys.length) {
            form.setFocus(keys[0]);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [form.formState.errors, form.setFocus]);

    const endDate = form.watch('endDate');
    const sprintTitle =  form.watch('name');

    const disableSprintCompletion =
        endDate > new Date() ||
        (isCreating
            ? !isPreviousSprintCompleted(endDate)
            : !model.allowSprintCompletion);

    const getMinDate = () => {
        return addDays(new Date(form.watch('startDate')), 1);
    };

    return (
        <FormProvider {...form}>
            <ReadOnlyTooltip childDisabled={!tokenService.hasEditingRights()}>
                <form onSubmit={form.handleSubmit(save)}>
                    <Typography
                        component="h1"
                        variant="h1"
                        id="sentinel-sprint-number"
                        data-testid="sentinel-sprint-number"
                    >
                        {sprintTitle}
                    </Typography>
                    <Typography component="h2" variant="h2">
                        {translation.metricsInfoTitle}
                    </Typography>
                    <Typography paragraph variant="body2">
                        {translation.metricsInfoIntro}
                    </Typography>

                    <Grid container spacing={4} sx={{ marginBottom: '2rem' }}>
                        <Grid item xs={12} sm={12}>
                            <TextFieldInput
                                label={translation.sprintName}
                                name="name"
                                tooltip_title={translation.sprintNameToolTip}
                                disabled={!tokenService.hasEditingRights()}
                                placeholder={translation.sprintNamePlaceholder}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <DateInput
                                label={translation.startDate}
                                name="startDate"
                                required
                                tooltip_title={translation.startDateToolTip}
                                disabled={!tokenService.hasEditingRights()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} />
                        <Grid item xs={12} sm={5}>
                            <DateInput
                                label={translation.endDate}
                                name="endDate"
                                required
                                tooltip_title={translation.endDateToolTip}
                                disabled={!tokenService.hasEditingRights()}
                                minDate={getMinDate()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <TextFieldInput
                                helper_text={translation.sprintGoalHelper}
                                label={translation.sprintGoal}
                                name="sprintGoal"
                                tooltip_title={translation.sprintGoalTooltip}
                                disabled={!tokenService.hasEditingRights()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <SelectInput
                                label={translation.sprintGoalSucceeded}
                                name="iterationGoalSucceeded"
                                options={iterationGoalSucceededOptions}
                                tooltip_title={
                                    translation.sprintGoalSucceededTooltip
                                }
                            />
                        </Grid>
                    </Grid>

                    {Object.entries(metricsData).map(([category, metrics]) => (
                        <Fragment key={category}>
                            <Divider sx={{ marginBottom: '1em' }} />
                            <MetricCategoryForm
                                category={category}
                                metrics={metrics}
                            />
                        </Fragment>
                    ))}

                    <Grid
                        container
                        direction="row"
                        spacing={4}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={4}>
                            <SwitchInput
                                name="isSprintCompleted"
                                label={translation.sprintStatus}
                                tooltip_title={translation.sprintStatusTooltip}
                                disabled={
                                    disableSprintCompletion ||
                                    !tokenService.hasEditingRights()
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                disabled={
                                    form.formState.isSubmitting ||
                                    !tokenService.hasEditingRights()
                                }
                                fullWidth
                                type="submit"
                                variant="contained"
                                data-testid="save-sprint-button"
                            >
                                {translation.saveSprint}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </ReadOnlyTooltip>
        </FormProvider>
    );
};
export default SprintForm;

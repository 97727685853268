import { addWeeks, differenceInDays } from 'date-fns';
import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import {
    providedMetricsApi,
    sprintApi,
    type Metric,
    type Sprint,
} from '../api/sentinel';

const calculateNextSprintDates = (sprint?: Sprint) => {
    if (sprint) {
        const { sprintStartDate, sprintEndDate } = sprint;
        const diff = differenceInDays(sprintEndDate, sprintStartDate);
        const increment = Math.ceil(diff / 7);
        const startDate = addWeeks(sprintStartDate, increment);
        const endDate = addWeeks(sprintEndDate, increment);
        return { startDate, endDate };
    } else {
        return {};
    }
};

function createNewSprint(example: Sprint | undefined) {
    const nextNo = 1 + (example?.sprintNo ?? 0);
    const { startDate, endDate } = calculateNextSprintDates(example);
    return {
        sprintNo: nextNo,
        name: '',
        sprintStartDate: startDate,
        sprintEndDate: endDate,
        sprintGoal: '',
    };
}

const useSprint = (
    projectId: string,
    sprintId: number,
    isCreating: boolean,
) => {
    const projectSprints: UseQueryResult<Sprint[], Error> = useQuery<
        Sprint[],
        Error
    >(['sprints', projectId, sprintId], async ({ signal }) => {
        return sprintId
            ? await sprintApi.findSprints({ projectId }, { signal })
            : [];
    });

    const metricsData = useQuery<Record<string, Metric>, Error>(
        ['providedMetrics', projectId, sprintId],
        async ({ signal }) => {
            if (sprintId) {
                return providedMetricsApi
                    .getProvidedMetricsForSprint(
                        { projectId, sprintId },
                        { signal },
                    )
                    .then(result => {
                        return {
                            ...result,
                            iterationGoalSucceeded: isCreating
                                ? 'N'
                                : result.iterationGoalSucceeded,
                        };
                    });
            } else {
                return {};
            }
        },
    );

    const queryClient = useQueryClient();
    const isLoading = projectSprints.isLoading || metricsData.isLoading;
    const isError = projectSprints.isError || metricsData.isError;
    const error = projectSprints.error || metricsData.error;

    const findSprint = (sprints?: Sprint[]): Partial<Sprint> => {
        // when editing a sprint
        if (!isCreating && sprints) {
            return sprints.filter(sprint => sprint.sprintId === sprintId)[0];
        } else {
            // when creating a new sprint in a Project which already has sprints
            if (sprints?.length) {
                const sprintWithLatestId = sprints.reduce((latest, current) => {
                    return (latest.sprintId ?? 0) > (current.sprintId ?? 0)
                        ? latest
                        : current;
                });
                return createNewSprint(sprintWithLatestId);
            } else {
                // when creating the first sprint of a Project
                return createNewSprint(undefined);
            }
        }
    };

    const sprint = isError ? undefined : findSprint(projectSprints.data);
    const metrics = metricsData.data ?? {};

    const isPreviousSprintCompleted = (date: Date): boolean => {
        if (!projectSprints?.data || projectSprints.data.length === 0) {
            return true;
        }
        const previousSprint = projectSprints.data
            .filter(sprint => sprint.sprintEndDate < date)
            .sort(
                (a, b) =>
                    date.getTime() -
                    a.sprintEndDate.getTime() -
                    (date.getTime() - b.sprintEndDate.getTime()),
            )[0];

        return !previousSprint || previousSprint.sprintStatus === 'COMPLETED';
    };

    const saveSprint = useCallback(
        async (sprint: Sprint, providedMetrics: { [x: string]: Metric }) => {
            let newSprintId = sprintId;
            if (isCreating) {
                const { sprintId: createdSprintId } = await sprintApi.add({
                    projectId,
                    sprint: { ...sprint, projectId },
                });
                if (createdSprintId) {
                    newSprintId = createdSprintId;
                    await providedMetricsApi.updateProvidedMetrics({
                        projectId,
                        sprintId: createdSprintId,
                        requestBody: providedMetrics,
                    });
                }
            } else {
                await Promise.all([
                    sprintApi.update({
                        projectId,
                        sprintId: sprintId,
                        sprint: { ...sprint, projectId, sprintId: sprintId },
                    }),

                    providedMetricsApi.updateProvidedMetrics({
                        projectId,
                        sprintId,
                        requestBody: providedMetrics,
                    }),
                ]);
            }

            await Promise.all([
                queryClient.invalidateQueries([
                    'providedMetrics',
                    projectId,
                    sprintId,
                ]),
                queryClient.invalidateQueries(['sprints', projectId, sprintId]),
            ]);
            return newSprintId;
        },
        [queryClient, isCreating, projectId, sprintId],
    );

    return {
        isLoading,
        isError,
        error,
        sprint,
        metrics,
        saveSprint,
        isPreviousSprintCompleted,
    };
};

export default useSprint;

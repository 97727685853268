import { useQuery } from 'react-query';
import { ProjectRevisionsData } from './services/projectRevisionsData';
import projectRevisionsService from "./services/projectRevisionsService";

type UseProjectRevisionsResult = {
    isLoading: boolean;
    error: Error | null;
    data?:ProjectRevisionsData;
};

const useProjectRevisionsData = (
    projectId?: string,
): UseProjectRevisionsResult => {
    return useQuery<ProjectRevisionsData, Error>(
        'project',
        async () => {
            if (projectId) {
                return projectRevisionsService.getById(projectId);
            } else {
                return Promise.reject(new Error('projectId is undefined'));
            }
        },
        {
            cacheTime: 0,
            staleTime: 0,
        },
    )
}


export default useProjectRevisionsData;

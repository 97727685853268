import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useCalculatedMetricSpecs from '../services/useCalculatedMetricSpecs';
import tokenService from '../authentication/tokenService';
import ReadOnlyTooltip from '../common/ReadOnlyTooltip';
import Spinner from '../common/Spinner';
import theme from '../theme/theme';
import FilterBar from './components/FilterBar';
import ProjectsDataGrid from './components/ProjectsDataGrid';
import locationService from './services/locationsService';
import projectService, { ProjectListFilter } from './services/projectService';

const ProjectsList = () => {
    const { t } = useTranslation();
    const translation = {
        btnText: t('projectList_addBtn'),
        title: t('projectList_title'),
    };
    const [filter, setFilter] = useState<ProjectListFilter>({
        status: 'active',
        locationId: undefined,
    });

    const queryLocations = useQuery('locations', locationService.getLocations);
    const queryProjects = useQuery(['project', filter], () =>
        projectService.get(filter),
    );

    const { calculatedMetricSpecs } = useCalculatedMetricSpecs();

    const showOverallScore = tokenService.hasRole(
        calculatedMetricSpecs['overallScore']?.restriction?.readAccessRole,
    );

    if (queryLocations.isLoading || queryProjects.isLoading) {
        return <Spinner open={true} />;
    }
    return (
        <Box sx={{ backgroundColor: theme.palette.grey['100'] }}>
            <Container
                maxWidth={'xl'}
                sx={{
                    backgroundColor: theme.palette.common.white,
                    padding: '2rem 0',
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h1" component="h1">
                                {translation.title}
                            </Typography>
                            <ReadOnlyTooltip
                                childDisabled={!tokenService.hasEditingRights()}
                            >
                                <Button
                                    startIcon={<AddIcon/>}
                                    size="small"
                                    color="primary"
                                    href={'/Project'}
                                    disabled={!tokenService.hasEditingRights()}
                                    data-testid="addProjectButton"
                                >
                                    {translation.btnText}
                                </Button>
                            </ReadOnlyTooltip>
                        </Stack>
                            <FilterBar
                                filter={filter}
                                setFilter={setFilter}
                                locations={queryLocations.data ?? []}
                            />
                            <ProjectsDataGrid
                                projects={queryProjects.data ?? []}
                                activeFilter={filter.status !== 'inactive'}
                                showOverallScore={showOverallScore}
                            />
                           </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ProjectsList;

import {Metric} from "../../api/sentinel";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {useTranslation} from 'react-i18next';
import WidgetContainer from "../../dashboard/common/widget/WidgetContainer";
import WidgetHeader from "../../dashboard/common/widget/WidgetHeader";
import dateService from "../../services/dateService";

export interface Metrics {
    [key: string]: Metric
}

function joining(previousValue : string[], currentValue : string[] ): string[] {
    return previousValue.concat(currentValue);
}

function isUnique(value : string, index : number, array : string[] ) : boolean {
    return array.indexOf(value) === index;
}

function alphabeticOrder (a : string, b : string ) {
    return a.localeCompare(b);
}

function extractAllMetricsNames(revisions: Metrics[] | undefined, exclusions : string[] ) : string[] {
    return Array.isArray(revisions) && revisions.length
        ? revisions
            .map(Object.keys)
            .reduce(joining)
            .filter(isUnique)
            .filter(value => exclusions.indexOf(value) === -1)
            .sort(alphabeticOrder)
        : [];
}

const MetricsRevisions = ({revisions} : {revisions? : Metrics[]}) => {

    const {t} = useTranslation();

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: string,
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field:  fieldName,
            headerName: t( `sprintForm_${fieldName}_label` ),
            ...commonAttributes,
            ...options,
        };
    };

    const metricColumns : GridColDef[] = extractAllMetricsNames(
        revisions
        , ["updatedOn", "updatedBy", "createdBy", "createdOn"]
    )
        .map(value => {
            return field(value, {
                flex: 1.5,
                minWidth:220,
                hideable: false,
                filterable: true
            })
        })

    const extraColumns: GridColDef[] = [
        field('updatedOn', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateTimeFormat(new Date( value )),
        }),
        field('updatedBy', {
            flex: 1.5,
            minWidth:220,
            hideable: false,
            filterable: true,
        }),
    ];

    const revisionColumns = extraColumns.concat(metricColumns);

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr'}
            gridTemplateAreas={'"H"\n"A"\n"B"'}
        >
            <WidgetHeader
                gridArea={'H'}
                title={t('metrics_revisions_title')}
                subtitle={t('metrics_revisions_subtitle')}
                trend={undefined}
            />
            <DataGrid
                density={'standard'}
                getRowId={(metrics: Metrics) => JSON.stringify(metrics)}
                columns={revisionColumns}
                rows={revisions ? revisions : []}
                disableRowSelectionOnClick
                sx={{
                    borderTopWidth: 0
                }}
            />
        </WidgetContainer>
        );
};

export default MetricsRevisions;
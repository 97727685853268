import { IUser, LocalizationHelper, PeoplePicker } from '@microsoft/mgt-react';
import { useController, useFormContext } from 'react-hook-form';
import { IDynamicPerson } from '@microsoft/mgt-components';
import { useTranslation } from 'react-i18next';
import HelpIconTooltip from '../../common/formContextBoundControls/HelpIconTooltip';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

const CustomPeoplePicker = ({
    name,
    tooltip_title,
}: {
    name: string;
    tooltip_title: string;
}) => {
    const { control } = useFormContext();
    const { field: peoplePickerField } = useController({ name, control });
    const { t: translate } = useTranslation();

    const handleOnChange = (event: CustomEvent<IDynamicPerson[]>) => {
        peoplePickerField.onChange(
            event.detail.map((value: IUser) => {
                return {
                    userId: value.id,
                    upn: value.userPrincipalName,
                };
            })?.pop(),
        );
    };

    LocalizationHelper.strings = {
        _components: {
            'people-picker': {
                maxSelectionsPlaceHolder: '',
            },
        },
    };

    return (
        <>
            <InputLabel shrink>
                {translate(`projectForm_${name}_label`)}
            </InputLabel>
            <Grid container justifyContent="space-between">
                <Grid item xs={11}>
                    <PeoplePicker
                        id={`${name}_id`}
                        userType="user"
                        personCardInteraction="hover"
                        selectionMode="single"
                        defaultSelectedUserIds={peoplePickerField?.value?.userId ? [peoplePickerField?.value?.userId] : []}
                        selectionChanged={handleOnChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <HelpIconTooltip title={tooltip_title} />
                </Grid>
            </Grid>
        </>
    );
};

export default CustomPeoplePicker;

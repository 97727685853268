import {projectApi, } from "../../api/sentinel";
import {ProjectRevisionsData} from "./projectRevisionsData";

const projectRevisionsService = {
    getById: async (projectId: string)=> {
        const [projectRevisions] = await Promise.all([
            projectApi.getProjectRevisions({ projectId }),
        ]);
        return {
            projectRevisions,
        } as ProjectRevisionsData;
    },
};

export default projectRevisionsService;

// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    upn?: string;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    createdOn?: Date;
    /**
     *
     * @type {string}
     * @memberof User
     */
    createdBy?: string;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    updatedOn?: Date;
    /**
     *
     * @type {string}
     * @memberof User
     */
    updatedBy?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): User {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: !exists(json, 'userId') ? undefined : json['userId'],
        upn: !exists(json, 'upn') ? undefined : json['upn'],
        createdOn: !exists(json, 'createdOn')
            ? undefined
            : new Date(json['createdOn']),
        createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
        updatedOn: !exists(json, 'updatedOn')
            ? undefined
            : new Date(json['updatedOn']),
        updatedBy: !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        userId: value.userId,
        upn: value.upn,
        createdOn:
            value.createdOn === undefined
                ? undefined
                : value.createdOn.toISOString(),
        createdBy: value.createdBy,
        updatedOn:
            value.updatedOn === undefined
                ? undefined
                : value.updatedOn.toISOString(),
        updatedBy: value.updatedBy,
    };
}
